import {SET_LANGUAGE} from '../constants/ActionTypes'
//KZ or RUS values only 
export default function (state = null, action) {
  switch (action.type) {
    case SET_LANGUAGE:
      return action.payload;
    default: return state;
  }
}

export const getLang = state => state.Language;