module.exports = {
  DoesntTurnOn: {
    RUS: "Не включается",
    ENG: "Doesn't turn on",
    CH: "无法开机",
  },
  UnstableWork: {
    RUS: "Неустойчивая работа",
    ENG: "Unstable work",
    CH: "工作不稳定",
  },
  ExtraneousNoise: {
    RUS: "Посторонний шум",
    ENG: "Extraneous noise",
    CH: "外来噪音",
  },
  Presale: { RUS: "Предпродажа", ENG: "Pre-sale", CH: "预售" },
  Adjusting: { RUS: "Регулировка", ENG: "Adjusting", CH: "调整" },
  SetUp: { RUS: "Настройка", ENG: "Set up", CH: "设置" },
  Repackaging: {
    RUS: "Переупаковка",
    ENG: "Repackaging",
    CH: "重新包装",
  },
  WontStart: {
    RUS: "Не заводится",
    ENG: "Won't start",
    CH: "无法开始",
  },
  DoesntPump: { RUS: "Не качает", ENG: "Doesn't pump", CH: "无法泵送" },
  TurnsOff: { RUS: "Глохнет", ENG: "Turns off", CH: "熄灭" },
  DoesntStabilize: {
    RUS: "Не стабилизирует",
    ENG: "Doesn't stabilize",
    CH: "不稳定",
  },
  NoWelding: { RUS: "Не варит", ENG: "No welding", CH: "不焊接" },
  Diagnostics: { RUS: "Диагностика", ENG: "Diagnostics", CH: "诊断" },
  ConductMaintenance: {
    RUS: "Провести ТО",
    ENG: "Conduct maintenance",
    CH: "进行维护",
  },
};
