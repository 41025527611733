import {SET_REPAIR} from '../constants/ActionTypes'

export default function (state = {}, action) {
  switch (action.type) {
    case SET_REPAIR:
      return getRepair(state, action)
    default:
      return state;
  }
}

const getRepair = (state, action) => {
  if (action.payload.hasOwnProperty('is_deff')) {
    return {}
  } else return {
    ...state, ...action.payload
  };
}
