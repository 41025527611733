import React from "react";
import PropTypes from "prop-types";
import "./NotAuthorized.scss";
import Login from "../Login/Login";

const NotAuthorized = (props) => {
  const { toggleIsLogin } = props;

  return (
    <div>
      <div className="header-not-auth">
        <h3>Добро пожаловать в ResantaService</h3>
      </div>
      <div className="center">
        <Login
          toggleLogin={() => toggleIsLogin(!!localStorage.getItem("token"))}
        />
      </div>
    </div>
  );
};

NotAuthorized.propTypes = {
  toggleIsLogin: PropTypes.func,
};

export default NotAuthorized;
