import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import store from './store';
import ErrorBoundary from "./views/Pages/ErrorBoundary/ErrorBoundary";
import {createRoot} from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <App/>
    </Provider>
  </ErrorBoundary>
);

serviceWorker.unregister();
