// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-login {
  padding: 20px;
  border-radius: 15px;
  margin:  100px auto;
  width: 500px;
  background-color: #f5f5f5;
}
`, "",{"version":3,"sources":["webpack://./src/views/Pages/Login/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,YAAY;EACZ,yBAAyB;AAC3B","sourcesContent":[".form-login {\n  padding: 20px;\n  border-radius: 15px;\n  margin:  100px auto;\n  width: 500px;\n  background-color: #f5f5f5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
