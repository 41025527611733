module.exports = {
  WithoutComments: {
    RUS: "Без замечаний",
    ENG: "Without comments",
    CH: "暂无评论",
  },
  Dirty: { RUS: "Грязное", ENG: "Dirty", CH: "肮脏的" },
  ExternalDamage: {
    RUS: "Внешние повреждения",
    ENG: "External damage",
    CH: "外部损伤",
  },
  UsedTracesOfUse: {
    RUS: "Следы Б/У эксплуатации",
    ENG: "Used, traces of use",
    CH: "使用过的使用痕迹",
  },
};
