import {BRANCH_ADDITIONAL_SERVICES} from '../constants/ActionTypes'

let initialState = {
  branch_paid_diagnostic_value: {},
  branch_cleaning_value: {},
  currency: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BRANCH_ADDITIONAL_SERVICES:
      return {
        ...state,
        ...action.payload
      };
    default: return state;
  }
}
