import React from "react";
import {Button, Result} from "antd";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: false };
  };

  promiseRejectionHandler = (event) => {
    this.setState({error: 'При загрузке страницы произошла ошибка.'});
  };

  static getDerivedStateFromError(error) {
    return { error: 'При загрузке страницы произошла ошибка.' };
  };

  componentDidCatch(error, errorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  };

  handleOnlineStatusChange = () => {
    if (!window.navigator.onLine) {
      this.setState({error: 'Отсутствует интернет соединение.'});
    }
  };

  componentDidMount() {
    window.addEventListener('unhandledrejection', this.promiseRejectionHandler)
    window.addEventListener('online', this.handleOnlineStatusChange);
    window.addEventListener('offline', this.handleOnlineStatusChange);
  };

  componentWillUnmount() {
    window.removeEventListener('unhandledrejection', this.promiseRejectionHandler);
    window.removeEventListener('online', this.handleOnlineStatusChange);
    window.removeEventListener('offline', this.handleOnlineStatusChange);
  };

  render() {
    if (this.state.error) {
      return  <div className="d-flex align-items-center justify-content-center vh-100">
        <Result
          status="500"
          subTitle={this.state.error}
          extra={<Button type="primary" onClick={() => window.location.href='/'}>Вернуться на главную</Button>}
        />
      </div>
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
