export const SET_USER = 'SET_USER';
export const SET_NUMBER = 'SET_NUMBER';
export const SET_ROUTES = 'SET_ROUTES';
export const SET_REPAIR = 'SET_REPAIR';
export const SET_COMMON_REPAIR = 'SET_COMMON_REPAIR';
export const BRANCH_ADDITIONAL_SERVICES = 'BRANCH_ADDITIONAL_SERVICES';
export const SET_CURRENCY = 'SET_CURRENCY';
export const SET_PRICE_TYPES = 'SET_PRICE_TYPES';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_COUNTRY = 'SET_COUNTRY';
export const SET_ASC = 'SET_ASC';
