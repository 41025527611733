import {SET_USER} from '../constants/ActionTypes'

export default function (state = null, action) {
  switch (action.type) {
    case SET_USER:
      return action.payload;

    default: return state;
  }
}

export const getUser = state => state.User;