import {combineReducers} from "redux";
import Number from "./Number";
import User from './User';
import Routes from "./Routes";
import Repair from "./Repair";
import CommonRepair from './CommonRepair';
import BranchAdditionalServices from './BranchAdditionalServices';
import Currency from './Currency';
import Language from './Language'
import PriceTypes from './PriceTypes';
import ASC from './ASC';

export default combineReducers({
  Number,
  User,
  Routes,
  Repair,
  CommonRepair,
  BranchAdditionalServices,
  Currency,
  PriceTypes,
  Language,
  ASC,
});
