import {Modal, notification} from "antd";

export const deleteIsEmptyProperties = obj => {
  //времненно !!!ПОПРАВИТЬ
  const tempObj = {};
  for (let key in obj) {
    if (typeof obj[key] === 'object') {
      tempObj[key] = deleteIsEmptyProperties(obj[key]);
      if (isEmpty(tempObj[key])) {
        delete tempObj[key];
      }
    } else if (typeof obj[key] === 'boolean') {
      tempObj[key] = obj[key]
    } else if (typeof obj[key] === 'number') {
      tempObj[key] = obj[key]
    } else if (!!obj[key]) {
      tempObj[key] = obj[key]
    }
  }
  return tempObj;
};

export const copyDeep = item => {
  if (!isObject(item) && !Array.isArray(item)) return false;
  const temp = Array.isArray(item) ? [...item] : {...item};
  if (Array.isArray(item)) {
    item.forEach((el, index) => temp[index] = isObject(el) ? copyDeep(el) : temp[index])
  } else Object.keys(item).forEach(key => temp[key] = isObject(temp[key]) ? copyDeep(temp[key]) :
    (Array.isArray(item) ? copyDeep(temp[key]) : temp[key]));
  return temp;
};

export const diff = (item, itemCompare, key) => {
  if (!isObject(item) && !Array.isArray(item)) return false;
  const result = Array.isArray(item) ? [] : {};
  if (Array.isArray(item)) {
    itemCompare.forEach(el => {
      if (isObject(el)) {
        const temp = hasItem(item, el, key ? key : 'id') ? diff(getItem(item, el), el) : el;
        if (!isEmpty(temp)) {
          result.push({
            ...el,
            ...temp,
          });
        }
      } else if (item.findIndex(oldEl => oldEl === el) === -1) {
        result.push(el);
      }
    })
  } else {
    Object.keys(itemCompare).forEach(key => {
      if (isObject(itemCompare[key]) || Array.isArray(itemCompare[key])) {
        const temp = item[key] ? diff(item[key], itemCompare[key]) : itemCompare[key];
        if (!isEmpty(temp)) {
          result[key] = temp;
        }
      } else if (itemCompare[key] !== item[key]) {
        result[key] = itemCompare[key];
      }
    });
  }
  return result;
};

export const isEmpty = obj => {
  return !Object.keys(obj || {}).length;
};

const isObject = obj => {
  return obj ? typeof obj === 'object' : false;
};

const hasItem = (items, el, key) => {
  return items.findIndex(item => item[key] === el[key]) !== -1;
};

const getItem = (items, el) => {
  return items.find(item => item.id === el.id);
};

export const infoError = (title, content = false) => {
  Modal.info({
    title: title,
    ...(content ? {content} : null),
    onOk() {
    },
  });
};

/**
 * View message info
 * @param {("bottomLeft"|"bottomRight"|"topLeft"|"topRight")} placement placement
 * @param {String} title title
 * @param {Number} duration duration
 */
export const infoMessage = (placement, title, duration = 4.5) => {
  notification['success']({
    message: title,
    placement,
    duration
  });
};

/**
 * View message error
 * @param {("bottomLeft"|"bottomRight"|"topLeft"|"topRight")} placement placement
 * @param {String} title title
 * @param {Number} duration duration
 */
export const infoTypeError = (placement, title, duration = 4.5) => {
  notification['error']({
    message: title,
    placement,
    duration
  });
};

export const warningMessage = (text) => {
  Modal.warning({
    title: text,
  });
};

export const printElement = (id, title = 'Штрихкод', style = '', html = '') => {
  const divToPrint = document.getElementById(id);
  if (divToPrint) divToPrint.style.display = "block";
  const printWindow = window.open('', '');
  printWindow.document.write('<html><head>');
  printWindow.document.title = title
  printWindow.document.write(`<style type="text/css">${style}</style></head><body>`);
  if (divToPrint) printWindow.document.write(divToPrint.outerHTML);
  printWindow.document.write(html);
  printWindow.document.write('</body></html>');
  printWindow.document.close();
  printWindow.print();
  if (divToPrint) divToPrint.style.display = "none";
}

export const printElementByInnerHtml = (html = '', style = '', title = '') => {
  const printWindow = window.open('', '');
  printWindow.document.write('<html><head>');
  printWindow.document.title = title
  printWindow.document.write(`<style type="text/css">${style}</style></head><body>`);
  printWindow.document.write(html);
  printWindow.document.write('</body></html>');
  printWindow.document.close();
  printWindow.print();
}
