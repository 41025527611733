import Modal from 'antd/lib/modal/Modal'
import React from 'react'
import {Button, Col, Row} from "antd";

const ModalSignUpInstruction = (props) => {
  const {isActive, toggle} = props
  return (
    <Modal
      open={isActive}
      onCancel={toggle}
      footer={[<Button type="primary" key="back" onClick={toggle}>Понятно</Button>]}
    >
      <Row>
        <Col span={24} className="font-weight-bold mb-3">Уважаемый пользователь!</Col>
      </Row>
      <Row>
        <Col span={24} className="mb-2">Чтобы получить доступ в базу Resanta Service необходимо выполнить следующие действия:</Col>
      </Row>
      <Row>
        <Col span={24} className="mb-2">Шаг 1. Пройти самостоятельную регистрацию на ресурсе HelpDesk:
          <a href="https://hd.hd-resanta.ru" target="_blank"> https://hd.hd-resanta.ru</a>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="mb-2">Шаг 2. Сменить должность в личном кабинете HelpDesk на актуальную, через обращение в ИТ-отдел.</Col>
      </Row>
      <Row>
        <Col span={24}>Шаг 3. Доступ в Resanta Service будет доступен через 30 минут после завершения Шага 2. Логин и пароль идентичны HelpDesk.</Col>
      </Row>
  </Modal>)
}
export default ModalSignUpInstruction
