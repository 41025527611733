import type from "./type";

const {
  checkingString10,
  checkingString255,
  checkingString400,
  checkingString20,
  checkingString32,
  checkingDate,
  checkingNumber2,
  checkingNumber11,
  checkingString500,
  checkingBoolean,
  checkingEmail,
  checkingString6,
  checkingString15,
  checkingString60,
  checkingString12,
  checkingString1000
} = type;

const index = {
  ur_index: checkingString10,
  fiz_index: checkingString10,
};

const id = {
  id: checkingNumber11,
};

const document = {
  document_type: checkingString255,
  document_serial: checkingString10,
  document_number: checkingString20,
  document_date: checkingDate,
  document_given: checkingString255,
};

const address = {
  ur_region: checkingString255,
  ur_district: checkingString255,
  ur_city: checkingString255,
  ur_np: checkingString255,
  ur_street: checkingString255,
  ur_house: checkingString255,
  ur_korp: checkingString255,
  ur_kv: checkingString255,

  fiz_region: checkingString255,
  fiz_district: checkingString255,
  fiz_city: checkingString255,
  fiz_np: checkingString255,
  fiz_street: checkingString255,
  fiz_house: checkingString255,
  fiz_korp: checkingString255,
  fiz_kv: checkingString255,
};

const client = {
  id: checkingNumber11,
  state_id: checkingNumber11,
  state: checkingString255,
  code: checkingString255,
  name: checkingString500,
  ur_name: checkingString500,
  inn: checkingString20,
  phone1: checkingString255,
  phone2: checkingString255,
  phone3: checkingString255,
  email: checkingString255,
  comment: checkingString255,
  is_phone: checkingBoolean,
  f: checkingString500,
  i: checkingString500,
  o: checkingString20,
  ...address,
  ...index,
  ...document,
};

const net_client = {
  states_id: checkingNumber11,
  name: checkingString255,
  ur_name: checkingString255,
  inn: checkingString60,
  id: checkingNumber11,
};

const login = {
  email: checkingEmail,
  password: checkingString32,
};

const problem = {
  id: checkingNumber11,
  category_problem_id:checkingNumber11,
  category_id: checkingNumber11,
  name: checkingString255,
  is_guaranty: checkingNumber2,
  added_to_report: checkingNumber2,
};


const repairReason = {
  id: checkingNumber11,
  name: checkingString255
};

const equipment = {
  id: checkingNumber11,
  name: checkingString255
};

const category = {
  id: checkingNumber11,
  name: checkingString400,
  article: checkingString10,
  parent_id: checkingNumber11
};

const repair = {
  id: checkingNumber11,
  service_id: checkingNumber11,
  engineer_id: checkingNumber11,
  product_id: checkingNumber11,
  factory_id: checkingNumber11,
  contract: checkingString32,
  supplier_id: checkingNumber11,
  author_id: checkingNumber11,
  customer_id: checkingNumber11,
  repair_reason_id: checkingNumber11,
  equipment_id: checkingNumber11,
  inspection_id: checkingNumber11,
  number: checkingString15,
  sn: checkingString32,
  product_select: checkingString255,
  is_phone: checkingBoolean,
  is_guarantee: checkingBoolean,
  is_product_clean: checkingBoolean,
  primary_diagnostic: checkingBoolean,
  is_individual: checkingBoolean,
  diagnostic_cost: checkingNumber11,
  clean_cost: checkingNumber11,
  email: checkingEmail,
  passport_url: checkingString255,
  comment: checkingString500,
  stage: checkingString32,
  product_type: checkingString255,
  repair_date: checkingDate,
  date: checkingString500,
  phone: checkingString15,
  date_sale: checkingDate,
  network_id: checkingNumber11,
};

const priceType = {
  name: checkingString255,
  code: checkingString255,
  currency_id: checkingNumber11,
  base_price_type: checkingString255,
  is_calculated: checkingNumber2,
  is_includes_VAT: checkingNumber2,
  is_includes_production_tax: checkingNumber2,
  id: checkingNumber11,
  rounding_order: checkingString255,
  extra_charge: checkingString255,
  discount_from: checkingNumber11,
  discount_to: checkingNumber11,
};

const repairPrice = {
  name: checkingString255,
  category_id: checkingNumber11,
  price: checkingString10,
  id: checkingNumber11,
  states_id: checkingNumber11
};

const stateFirm = {
  id: checkingNumber11,
  name: checkingString255,
  position: checkingString255,
  contact_person: checkingString255,
  address: checkingString255,
  phone: checkingString255,
  email: checkingString255,
  psrn: checkingString255,
  inn: checkingString255,
  kpp: checkingString255,
  checking_account: checkingString255,
  correspondent_account: checkingString255,
  checking_account_bank: checkingString255,
  bic: checkingString255,
  okpo: checkingString255,
};

const branch = {
  states_id: checkingNumber11,
  state_firms_id: checkingNumber11,
  email: checkingEmail,
  phone: checkingString15,
  address: checkingString255,
  name: checkingString255,
  login: checkingString32,
  pass: checkingString32,
  sourceAddress: checkingString255,
  subDismantleId: checkingNumber11,
  engineerSalary: checkingNumber11,
  working_hours: checkingString255,
};

const factory = {
  abbreviation: checkingString6,
  id: checkingNumber11
};

const additionalServiceState = {
  states_id: checkingNumber11,
  id: checkingNumber11,
  service_id: checkingNumber11,
  price: checkingString255,
  category_id: checkingNumber11,
  currency_id: checkingNumber11,
  code: checkingString255,
};

const additionalService = {
  name: checkingString255,
  id: checkingNumber11,
  type: checkingString255
};

const sparePart = {
  name: checkingString1000,
  vendor_code: checkingString12,
  category_id: checkingNumber11,
  id: checkingNumber11
};

const warrantyPeriod = {
  name: checkingString255,
  warranty_periods_id: checkingNumber11,
  term: checkingNumber11,
  repair_period: checkingNumber11,
  warranty_periods_category_id: checkingNumber11
};

const constant_values = {
  id: checkingNumber11,
  name: checkingString255,
  value: checkingString255,
};



export default {
  client,
  login,
  problem,
  id,
  repairReason,
  equipment,
  category,
  repair,
  priceType,
  repairPrice,
  net_client,
  stateFirm,
  branch,
  factory,
  additionalService,
  additionalServiceState,
  sparePart,
  warrantyPeriod,
  constant_values
};
