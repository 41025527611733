const checkingString = (size, str) => {
  return typeof str === 'string' && str.length <= size;
};

const checkingNumber = (number, size) => {
  return parseInt(number) === number && number.toString().length <= size;
};

const checkingEmail = email => {
  // SPEC https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
  const size = 64;
  const reg = /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  return reg.test(email) && email.length <= size;
};

const checkingNumber11 = (number) => {
  const size = 11;
  return checkingNumber(number, size);
};

const checkingNumber255 = (number) => {
  const size = 255;
  return checkingNumber(number, size);
};

const checkingNumber2 = (number) => {
  const size = 2;
  return checkingNumber(number, size);
};

const checkingDecimal1 = (number) => {
  return typeof +number === 'number' && +number <= 1 && +number >= 0.001
};

const checkingString6 = (str) => {
  const size = 6;
  return checkingString(size, str);
};

const checkingString10 = (str) => {
  const size = 10;
  return checkingString(size, str);
};

const checkingString12 = (str) => {
  const size = 12;
  return checkingString(size, str);
};

const checkingString60 = (str) => {
  const size = 60;
  return checkingString(size, str);
};

const checkingString15 = (str) => {
  const size = 15;
  return checkingString(size, str);
};

const checkingString32 = (str) => {
  const size = 32;
  return checkingString(size, str);
};

const checkingString20 = (str) => {
  const size = 20;
  return checkingString(size, str);
};

const checkingString255 = (str) => {
  const size = 255;
  return checkingString(size, str);
};

const checkingString400 = (str) => {
  const size = 400;
  return checkingString(size, str);
};

const checkingString500 = (str) => {
  const size = 500;
  return checkingString(size, str);
};

const checkingString1000 = (str) => {
  const size = 1000;
  return checkingString(size, str);
};

const checkingBoolean = value => {
  return typeof value === 'boolean';
};

const checkingDate = (dateString) => {
  const regex_date = /^\d{4}\d{1,2}\d{1,2}$/;

  if(!regex_date.test(dateString)) return false;

  const parts = dateString.split("-");
  const day = parseInt(parts[2], 10);
  const month = parseInt(parts[1], 10);
  const year = parseInt(parts[0], 10);

  if(year < 1000 || year > 3000 || month === 0 || month > 12) return false;

  const monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

  if(year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) monthLength[1] = 29;

  return day > 0 && day <= monthLength[month - 1];
};


export default {
  checkingNumber2: checkingNumber2,
  checkingNumber11: checkingNumber11,
  checkingNumber255: checkingNumber255,
  checkingString10: checkingString10,
  checkingString15: checkingString15,
  checkingString20: checkingString20,
  checkingString32: checkingString32,
  checkingString255: checkingString255,
  checkingString400: checkingString400,
  checkingString500: checkingString500,
  checkingBoolean: checkingBoolean,
  checkingEmail: checkingEmail,
  checkingDate: checkingDate,
  checkingDecimal1: checkingDecimal1,
  checkingString60: checkingString60,
  checkingString6: checkingString6,
  checkingString12: checkingString12,
  checkingString1000: checkingString1000,
}
