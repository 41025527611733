const en = {
  translation: {
    report_map: 'Report map',
    repairs_period: 'Repairs period',
    shipments_period: 'Shipments period',
    contract: 'Contract',
    factory: 'Factory',
    guarantee: 'Guarantee',
    all: 'All',
    yes: 'Yes',
    no: 'No',
    product_nomenclature: 'Product_nomenclature',
    list_of_faults: 'List of faults',
    quantity_of_shipments: 'Quantity of shipments, more (pcs)',
    defect_statistics_report: 'Defect statistics report',
    for_the_period_of_repairs: 'For the period of repairs',
    regarding_shipments_for_the_period: 'Regarding shipments for the period',
    for_guarantee_cases: 'For guarantee cases: ',
    by_goods_from_the_nomenclature: 'By goods from the nomenclature: ',
    by_contracts: 'By contracts: ',
    by_factories: 'By factories: ',
    contracts: 'Contracts',
    shipments_per_period: 'Shipments per period',
    repairs_for_the_period: 'Repairs for the period',
    of_defects: '% of defects',
    total: 'Total',
    close: 'Close',
    extra_filters: 'Extra filters',
  }
}

const ru = {
  translation: {
    report_map: 'Карта отчета',
    repairs_period: 'Период ремонтов',
    shipments_period: 'Период отгрузки',
    contract: 'Контракт',
    factory: 'Фабрика',
    guarantee: 'Гарантия',
    all: 'Все',
    yes: 'Да',
    no: 'Нет',
    product_nomenclature: 'Список номенклатуры',
    list_of_faults: 'Список неисправностей',
    quantity_of_shipments: 'Кол-во отгрузок, более (шт)',
    defect_statistics_report: 'Отчет по статистике брака',
    for_the_period_of_repairs: 'За период выполненных ремонтов',
    regarding_shipments_for_the_period: 'в отношении к отгрузкам за период',
    for_guarantee_cases: 'По гарантийным случаям: ',
    by_goods_from_the_nomenclature: 'По номенклатуре из списка: ',
    by_contracts: 'По контрактам: ',
    by_factories: 'По фабрикам: ',
    contracts: 'Контракты',
    shipments_per_period: 'Отгрузки за период',
    repairs_for_the_period: 'Поступило в ремонт',
    of_defects: '% брака',
    total: 'Итого',
    close: 'Закрыть',
    extra_filters: 'Дополнительные фильтры',
  }
}

const jp = {
  translation: {
    report_map: '报告地图',
    repairs_period: '维修期',
    shipments_period: '出货期',
    contract: '合同',
    factory: '工厂',
    guarantee: '保证',
    all: '全部',
    yes: '是的',
    no: '不',
    product_nomenclature: '产品命名法',
    list_of_faults: '故障列表',
    quantity_of_shipments: '出货数量, 更多(pcs)',
    defect_statistics_report: '缺陷统计报告',
    for_the_period_of_repairs: '在维修期间',
    regarding_shipments_for_the_period: '关于期间的出货量',
    for_guarantee_cases: '对于担保情况: ',
    by_goods_from_the_nomenclature: '按商品命名: ',
    by_contracts: '按合同: ',
    by_factories: '按工厂: ',
    contracts: '合同',
    shipments_per_period: '每个时期的出货量',
    repairs_for_the_period: '期间维修',
    of_defects: '缺陷百分比',
    total: '全部的',
    close: '关',
    extra_filters: '附加过滤器',
  }
}

export default {
  resources: {
    en,
    ru,
    jp
  },
  lng: "ru",
  fallbackLng: "ru",

  interpolation: {
    escapeValue: false
  }
}
