import {SET_NUMBER} from '../constants/ActionTypes'


export default function (state = [], action) {
  switch (action.type) {
    case SET_NUMBER:
      return action.payload;
    default:
      return state;
  }
}
