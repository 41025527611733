import * as types from "../constants/ActionTypes";

export const setUser = (user) => {
  return {
    type: types.SET_USER,
    payload: user,
  };
};

export const setNumber = (nav) => {
  return {
    type: types.SET_NUMBER,
    payload: nav,
  };
};

export const setRoutes = (routes) => {
  return {
    type: types.SET_ROUTES,
    payload: routes,
  };
};

export const setRepair = (repair) => {
  return {
    type: types.SET_REPAIR,
    payload: repair,
  };
};

export const setCommonRepair = (common) => {
  return {
    type: types.SET_COMMON_REPAIR,
    payload: common,
  };
};

export const setBranchAdditionalServices = (data) => {
  return {
    type: types.BRANCH_ADDITIONAL_SERVICES,
    payload: data,
  };
};

export const setCurrency = (data) => {
  return {
    type: types.SET_CURRENCY,
    payload: data,
  };
};

export const setPriceTypes = (data) => {
  return {
    type: types.SET_PRICE_TYPES,
    payload: data,
  };
};

export const setLanguage = (language) => {
  return {
    type: types.SET_LANGUAGE,
    payload: language,
  };
};

export const setASC = (asc) => {
  return {
    type: types.SET_ASC,
    payload: asc,
  };
};
