import React, { useState } from "react";
import "./style.css"
import PropTypes from "prop-types";
import {Button, Checkbox, Form, Input, Spin} from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import validator from "../../../modules/validator/validator";
import { infoTypeError } from "../../../modules/helper";
import { login } from "../../../services/auth/auth";
import ModalSignUpInstruction from "../../../components/Modals/ModalSignUpInstructions";

const Login = (props) => {
  const { toggleLogin } = props;
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [authorizationLoading, setAuthorizationLoading] = useState(false);
  const [isActiveInstructionModal, setActiveInstructionModal] = useState(false);
  const [isActiveASC, setActiveASC] = useState(false);

  const changeEmail = (e) => {
    setEmail(e.target.value);
  };

  const changePassword = (e) => {
    setPassword(e.target.value);
  };

  const toggleLoading = () => {
    setAuthorizationLoading(false);
  };

  const normalizeParams = () => {
    return {
      method: "POST",
      body: {
        email: email,
        password: password,
      },
    };
  };

  const showError = (textError) => {
    infoTypeError("bottomRight", textError);
  };

  const isDisableLoginBtn = () => {
    return !!email && !!password;
  };

  const validData = (user) => {
    const tempUser = { ...user };
    return validator.login(tempUser);
  };

  const onLogin = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const params = normalizeParams();
    const valid = validData(params.body);

    if (valid.status) {
      showError("Неверный формат данных");
      return false;
    }

    if (isDisableLoginBtn() || authorizationLoading) {
      toggleLoading();
      params.body.isASC = isActiveASC;
      const response = await login(params);
      if (response.status === 200) {
        localStorage.setItem("token", response.data.token);
        if (isActiveASC) {
          localStorage.setItem("isASC", 'true');
        }
        toggleLogin();
      } else if (response.status === 400) {
        showError("В доступе отказано");
        toggleLoading();
      } else {
        showError(response.message);
        toggleLoading();
      }
    }
  };

  const handleCheckboxChange = (event) => {
    setActiveASC(event.target.checked);
  }

  return (
    <Form
      className="form-login"
      name="basic"
      initialValues={{
        remember: true,
      }}
    >
      <h3>Авторизация</h3>
      <Form.Item
        className="mb-4"
        name="username"
        rules={[
          {
            required: true,
            message: "Пожалуйста, укажите ваш email!",
          },
        ]}
      >
        <Input
          placeholder="Username"
          prefix={<UserOutlined />}
          value={email}
          onChange={changeEmail}
        />
      </Form.Item>

      <Form.Item
        // label="Password"
        className="mb-1"
        name="password"
        rules={[
          {
            required: true,
            message: "Пожалуйста, укажите ваш password!",
          },
        ]}
      >
        <Input.Password
          placeholder="Password"
          prefix={<LockOutlined />}
          value={password}
          onChange={changePassword}
        />
      </Form.Item>
      <Form.Item className="mb-1">
        <Checkbox onChange={handleCheckboxChange}>АСЦ</Checkbox>
      </Form.Item>
      <Form.Item>
        <Spin spinning={authorizationLoading}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%" }}
            disabled={!isDisableLoginBtn() || authorizationLoading}
            onClick={onLogin}
          >
            Войти
          </Button>
          <Button
            type="default"
            style={{ width: "100%", marginTop: 10 }}
            onClick={setActiveInstructionModal}
          >
            Зарегистрироваться
          </Button>
        </Spin>
      </Form.Item>
      <ModalSignUpInstruction
        title="Инструкция для регистрации"
        isActive={isActiveInstructionModal}
        toggle={() => setActiveInstructionModal(false)}
      />
    </Form>
  );
};

Login.propTypes = {
  toggleLogin: PropTypes.func.isRequired,
};

export default Login;
