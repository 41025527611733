export const ShowCorrectCosts = async (setCurrencyBase, user) => {
  const currencyBase = user.currency.tenge.currency_base || 1;
  const whichCountry = localStorage.getItem("country");
  if (whichCountry === "KZ") {
    setTimeout(() => setCurrencyBase(currencyBase), 1000);
  }
};

export const FormatUrClientAddress = (counterParty) => {
  const {
    ur_city,
    ur_district,
    ur_index,
    ur_korp,
    ur_kv,
    ur_np,
    ur_region,
    ur_street,
  } = counterParty;
  const cityFormatter = (ur_city = "") => {
    if (ur_city === null) {
      return "";
    }
    if (ur_city.startsWith("г.") || ur_city.endsWith("г.")) {
      return "г. " + ur_city.replace("г.", "").trim();
    }
    if (ur_city.startsWith("г") || ur_city.endsWith("г")) {
      return "г. " + ur_city.replace("г", "").trim();
    }
    return "г. " + ur_city.trim();
  };
  
  const upFormatter = (ur_np = "") => {
    if (ur_np === null) {
      return "";
    }
    if (ur_np.startsWith("село")) {
      return ur_np.replace("село", "с.").trim();
    }
    if (ur_np.endsWith("п")) {
      let stringToReturn = "";
      stringToReturn = "п. " + ur_np.replace("п", "").trim();
      return stringToReturn;
    }
    if (ur_np.endsWith("п.")) {
      let stringToReturn = "";
      stringToReturn = "п. " + ur_np.replace("п.", "").trim();
      return stringToReturn;
    }
    if (ur_np.endsWith("пгт")) {
      let stringToReturn = "";
      stringToReturn = "пгт " + ur_np.replace("пгт", "").trim();
      console.log({stringToReturn})
      return stringToReturn;
    }
    return ur_np;
  };
  const streetFormatter = (ur_street = "") => {
    if (!ur_street) {
      return "";
    }
    if (
      !ur_street.startsWith("ул") &&
      !(
        ur_street.endsWith("м-н") ||
        ur_street.endsWith("мкр") ||
        ur_street.endsWith("проезд") ||
        ur_street.endsWith("переулок")
      )
    ) {
      return "ул. " + ur_street;
    }
    
    return ur_street;
  };
  const kvFormatter = (kv = "") => {
    if (!kv) {
      return "";
    }
    return "кв. " + kv;
  };
  const korpFormatter = (kv = "") => {
    if (!kv) {
      return "";
    }
    return "кор. " + kv;
  };
  const indexOrDistrictFormatter = (str = "") => {
    if (!str) {
      return "";
    }
    return str;
  };
  
  const regionFormatter = (region = "") => {
    if (!region) {
      return "";
    }
    if (region.endsWith("обл")) {
      return region + ".";
    }
    if (region.endsWith("область")) {
      return region.replace("область", "обл.");
    }
    return region + " обл.";
  };
  return (
    regionFormatter(ur_region) +
    " " +
    indexOrDistrictFormatter(ur_district) +
    " " +
    cityFormatter(ur_city) +
    " " +
    upFormatter(ur_np) +
    " " +
    streetFormatter(ur_street) +
    " " +
    korpFormatter(ur_korp) +
    " " +
    kvFormatter(ur_kv) +
    " " +
    indexOrDistrictFormatter(ur_index)
  );
};

export const getExtra = (user, type = "") => {
  const findedItem = user.extra[type];
  return findedItem ? findedItem : false
}

export const hasProperty = (obj, key) => obj.hasOwnProperty(key)

export const reasonList = [
  {
    label: 'Дополнительно',
    value: 1
  },
  {
    label: 'Не включается',
    value: 8
  },
  {
    label: 'Неустойчивая работа',
    value: 2
  },
  {
    label: 'Посторонний шум',
    value: 3
  },
  {
    label: 'Предпродажа',
    value: 4
  },
  {
    label: 'Регулировка',
    value: 5
  },
  {
    label: 'Настройка',
    value: 6
  },
  {
    label: 'Переупаковка',
    value: 7
  },
  
  {
    label: 'Не заводится',
    value: 11
  },
  {
    label: 'Не качает',
    value: 12
  },
  {
    label: 'Глохнет',
    value: 13
  },
  { 
    label: 'Не стабилизирует',
    value: 14
  },
  {
    label: 'Не варит',
    value: 15
  },
  {
    label: 'Диагностика',
    value: 16
  },
  {
    label: 'Провести ТО',
    value: 17
  },
]

export const equipmentsList = [
  {
    label: 'В коробке',
    value: 6
  },
  {
    label: 'В кейсе',
    value: 2
  },
  {
    label: 'Без коробки',
    value: 3
  },
  {
    label: 'В сборе',
    value: 4
  },
  {
    label: 'Разобран',
    value: 5
  },
  {
    label: 'Дополнительно',
    value: 1
  }
]

export const inspectionList = [
  {
    label: 'Без замечаний',
    value: 5
  },
  {
    label: 'Грязное',
    value: 2
  },
  {
    label: 'Внешние повреждения',
    value: 3
  },
  {
    label: 'б/у следы эксплуатации',
    value: 4
  },
  {
    label: 'Дополнительно',
    value: 1
  },
]

export const repairContractorList = [
  {
    label: 'Инженер',
    value: 1
  },
  {
    label: 'Аутсорс',
    value: 2
  }
]

export const style100percent = {
  width: '100%'
}

export const getValuePaidDiagnostic = (kz, byn, paidDiagnosticsValue) => paidDiagnosticsValue[`price${kz ? '_kzt' : (byn ? '_byn' : '')}`] || 0

export const getLang = (kz, byn) => kz ? 'kk' : (byn ? 'be' : 'ru')

export const getCurrency = (kz, byn) => kz ? 'KZT' : (byn ? 'BYR' : 'RUB')

export const getNumberFromCurrency = (num, kz, byn) => new Intl.NumberFormat(getLang(kz, byn), {
  style: 'currency',
  minimumFractionDigits: 2,
  currency: getCurrency(kz, byn)
}).format(num)

export const formatPaidDiagnostic = (paidDiagnosticsValue, kz, byn) => getNumberFromCurrency(getValuePaidDiagnostic(kz, byn, paidDiagnosticsValue), kz, byn)

export const defTypeText = {
  type: '',
  style: {
    fontSize: '16px'
  }
}

export const defValueType = {
  type: '',
  code: true,
  style: {
    fontSize: '18px',
    fontWeight: 'bold'
  }
}

export const successType = {
  type: 'success',
  code: true,
  style: {
    fontSize: '16px',
  }
}

export const switchRuToEng = (str) => {
  const layoutMap = {
    'й': 'q', 'ц': 'w', 'у': 'e', 'к': 'r', 'е': 't', 'н': 'y', 'г': 'u', 'ш': 'i', 'щ': 'o', 'з': 'p', 'х': '[', 'ъ': ']',
    'ф': 'a', 'ы': 's', 'в': 'd', 'а': 'f', 'п': 'g', 'р': 'h', 'о': 'j', 'л': 'k', 'д': 'l', 'ж': ';', 'э': '\'',
    'я': 'z', 'ч': 'x', 'с': 'c', 'м': 'v', 'и': 'b', 'т': 'n', 'ь': 'm', 'б': ',', 'ю': '.',
    'Й': 'Q', 'Ц': 'W', 'У': 'E', 'К': 'R', 'Е': 'T', 'Н': 'Y', 'Г': 'U', 'Ш': 'I', 'Щ': 'O', 'З': 'P', 'Х': '{', 'Ъ': '}',
    'Ф': 'A', 'Ы': 'S', 'В': 'D', 'А': 'F', 'П': 'G', 'Р': 'H', 'О': 'J', 'Л': 'K', 'Д': 'L', 'Ж': ':', 'Э': '"',
    'Я': 'Z', 'Ч': 'X', 'С': 'C', 'М': 'V', 'И': 'B', 'Т': 'N', 'Ь': 'M', 'Б': '<', 'Ю': '>',
    '.': '/', ',': '?', '"': '@', '№': '#', ';': '$', ':': '^', '?': '&', 'Ё': '~', 'ё': '`',
  };

  return str.split('').map(char => layoutMap[char] || char).join('');
}
